import React from 'react'
import Layout from '../hoc/Layout/Layout'
import TermsOfUse from '../components/Pages/TermsOfUse/TermsOfUse'

export default () => {
  return (
    <Layout>
      <TermsOfUse />
    </Layout>
  )
}
